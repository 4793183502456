<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <nav ref="menu" id="sidebar">
            <div class="shadow-bottom"></div>
            <perfect-scrollbar class="list-unstyled menu-categories" tag="ul" :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">
                <template v-for="(item, index) in sidebarRoutes">
                    <BaseSidebarItem v-if="item.can()" :label="$t(`admin.sidebar.${item.name}`)" :url="`/${ROOT}/${item.name}`" :icon="item.name" :key="index" />
                </template>
            </perfect-scrollbar>
        </nav>
    </div>
</template>

<style lang="scss" scoped>
    .role-name {
        padding: 9px 10px;
        border-bottom: 1px solid #a9aec5;
    }
</style>

<script setup>
    import { onMounted, ref } from 'vue';
    import { useStore } from 'vuex';
    import BaseSidebarItem from '../custom/BaseSidebarItem.vue';
    import { routes, ROOT } from '@/router/modules/admin';

    const sidebarRoutes = routes.filter((e) => !e.notSidebar);
    const store = useStore();

    const menu_collapse = ref('dashboard');

    onMounted(() => {
        const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
        if (selector) {
            const ul = selector.closest('ul.collapse');
            if (ul) {
                let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            } else {
                selector.click();
            }
        }
    });

    const toggleMobileMenu = () => {
        if (window.innerWidth < 991) {
            store.commit('toggleSideBar', !store.state.is_show_sidebar);
        }
    };
</script>
