import $cookies from 'vue-cookies';
import { ROLE_ADMIN, ROLE_USER } from '@/enums/role';
import { isUndefinedOrEmptyStringOrNull } from './reuseble';

export function getUser() {
    return $cookies.get('user');
}
export function isAdmin() {
    return getUser()?.role == ROLE_ADMIN;
}
export function isUser() {
    return getUser()?.role == ROLE_USER;
}

export function getRoleText(role) {
    if (isUndefinedOrEmptyStringOrNull(role)) {
        return null;
    }
    if (isNaN(role)) {
        return role;
    }
    if (role == ROLE_ADMIN) {
        return 'admin';
    }
    if (role == ROLE_USER) {
        return 'factory';
    } else {
        return role;
    }
}
