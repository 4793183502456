export default {
    namespaced: true,
    state: {
        selectedItems: {
            main: [],
            additional: [],
        },
    },
    mutations: {
        setSelectedItems(state, res) {
            state.selectedItems[res.name] = res.value;
        },
        removeItem(state, item) {
            state.selectedItems[item.name].splice(state.selectedItems[item.name].indexOf(item.value), 1);
        },
    },
};
