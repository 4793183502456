<template>
    <button class="btn mr-2" :disabled="loading" @click="ok">
        <span stylw="width:3px;height:3px" v-show="loading" class="spinner-border spinner-border-sm text-white me-2 align-self-center loader-sm">Loading...</span>
        <slot />
    </button>
</template>

<script setup>
    import { defineEmits, defineExpose, ref } from 'vue';
    const emits = defineEmits(['ok']);
    const loading = ref(false);

    function ok() {
        emits('ok', (v) => {
            loading.value = v;
        });
    }

    defineExpose({
        ok,
    });
</script>

<style lang="scss" scoped></style>
