import { authMiddleware, checkRoleMiddleware } from '@/middleware';
import { isAdmin, isAdminLaboratory, isAdminRegistration, isAdminUzi, isUser } from '@/utils/role';

export const ROOT = 'dashboard';

export const REGISRATION = 'registration';
export const LABORATORY = 'laboratory';
export const DOCUMENTS = 'documents';
export const INSPECTIONSHEET = 'inspection-sheet';
export const ULTRASOUND = 'ultrasound';
export const PRICELIST = 'price-list';
export const MEDICINES = 'medicines';
export const EMPLOYEES = 'employees';

const ADD = 'add';
const EDIT = 'edit';
const VIEW = 'view';
export const routes = [
    {
        name: DOCUMENTS,
        can: () => isAdmin() || isUser(),
    },
    {
        name: REGISRATION,
        can: () => isAdmin(),
    },
];

export const createRoutes = () => {
    const result = routes
        .map((route) => {
            return [
                {
                    path: `/${ROOT}/${route.name}`,
                    name: route.name,
                    meta: {
                        can: () => route.can(),
                    },
                    beforeEnter: [authMiddleware, checkRoleMiddleware],
                    component: () => import(`@/views/admin/${route.name}`),
                },
                {
                    path: `/${ROOT}/${route.name}/${ADD}`,
                    name: route.name + '-' + ADD,
                    meta: {
                        can: () => route.can(),
                    },
                    beforeEnter: [authMiddleware, checkRoleMiddleware],
                    component: () => import(`@/views/admin/${route.name}/${ADD}.vue`),
                },
                {
                    path: `/${ROOT}/${route.name}/:id/${EDIT}`,
                    name: route.name + '-' + EDIT,
                    beforeEnter: [authMiddleware, checkRoleMiddleware],
                    meta: {
                        can: () => route.can(),
                    },
                    component: () => import(`@/views/admin/${route.name}/${ADD}.vue`),
                },
            ];
        })
        .flat();

    return [
        ...result,
        {
            path: `/${ROOT}/${DOCUMENTS}/:id/${VIEW}`,
            name: DOCUMENTS + '-' + VIEW,
            beforeEnter: [authMiddleware, checkRoleMiddleware],
            meta: {
                can: () => isAdmin(),
            },

            component: () => import(`@/views/admin/${DOCUMENTS}/${VIEW}.vue`),
        },
    ];
};
